<template>
  <div id="app" @scroll="handleScroll($event)">
    <Top></Top>
    <fix-bar></fix-bar>
    <router-view
      :partOneActive="partOneActive"
      :partTwoActive="partTwoActive"
      :partThreeActive="partThreeActive"
      :partFourActive="partFourActive"
    />
    <Bott></Bott>
  </div>
</template>
<script>
import Top from "./components/top.vue";
import Bott from "./components/bott.vue";
import FixBar from "./components/fixBar.vue";
export default {
  name: "app",
  components: {
    Top,
    Bott,
    FixBar,
  },
  data() {
    return {
      partOneActive: false,
      partTwoActive: false,
      partThreeActive: false,
      partFourActive:false
    };
  },
  methods: {
    handleScroll(e) {
      let scrollTop = e.srcElement.scrollTop || e.target.scrollTop;
      if (scrollTop >= 400) {
        this.partOneActive = true;
      } 
      if (scrollTop >= 1300) {
        this.partTwoActive = true;
      }
      if (scrollTop >= 2000) {
        this.partThreeActive = true;
      }
      if (scrollTop >= 3100) {
        this.partFourActive = true;
      }
      
    },
  },
};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
html, body, #app {
  height: 100%;
  overflow: auto;
}
</style>
