<template>
  <div id="bottom" class="bottom">
    <div class="bottomLogo">
      <router-link to="/"><img :src="`img/logo.png`" alt="" /></router-link>
    </div>
    <div class="line address">
      <div class="tit">Address</div>
      <div class="info">
        <div class="item">
          <img :src="`img/icon1.png`" alt="" />
          <div class="txt">
            Room 1710,, Tian E Hu Road,<br />
            Business Port, Hefei
          </div>
        </div>
      </div>
    </div>
    <div class="line contact">
      <div class="tit">Contact</div>
      <div class="info">
        <div class="item">
          <img :src="`img/icon2.png`" alt="" />
          <div class="txt">+86 166 5115 5567</div>
        </div>
        <div class="item">
          <img :src="`img/icon3.png`" alt="" />
          <div class="txt">demon.nie@comeffie.com</div>
        </div>
      </div>
    </div>
    <div class="line wechat">
      <div class="tit">Wechat</div>
      <img class="wei" :src="`img/wei.png`" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "bott",
};
</script>



<style lang="stylus" scoped  rel="stylesheet/stylus">
.bottom {
  width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 50px;
  padding-top: 130px;

  .bottomLogo {
    width: 148px;
    height: 73px;

    a {
      display: block;
      width: 148px;
      height: 73px;

      img {
        width: 148px;
        height: 73px;
      }
    }
  }

  .line {
    width: 260px;

    .tit {
      height: 73px;
      border-bottom: 1px solid #000;
      line-height: 73px;
      box-sizing: border-box;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      margin-bottom: 25px;
    }

    .info {
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 27px;

        img {
          width: 19px;
          height: 19px;
          margin-right: 20px;
        }

        .txt {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          line-height: 19px;
        }
      }
    }

    .pays {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 29px;
    }

    .wei {
      width: 143px;
      height: 143px;
    }
  }
}
</style>