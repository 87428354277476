<template>
  <div class="fixBar">
    <a @click="jump('#bottom')" class="icon">
      <img :src="`img/fix1.png`" alt="" />
    </a>
    <a @click="jump('#bottom')" class="icon two">
      <img :src="`img/fix2.png`" alt="" />
    </a>
    <a @click="jump('#app')" class="icon three">
      <img :src="`img/fix3.png`" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    jump(id) {
      document.querySelector(id).scrollIntoView(true);
    },
  },
};
</script>

<style lang="stylus" scoped>
.fixBar {
  position: fixed;
  top: 400px;
  right: 0;
  width: 34px;
  height: 122px;
  z-index: 99999;
  padding-top: 22px;
  box-sizing: border-box;
  background: url('../../public/img/fixBg.png') no-repeat center top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .icon {
    width: 16px;
    height: 16px;
    cursor: pointer;

    &.two {
      height: 13px;
    }

    &.three {
      width: 25px;
      height: 24px;
    }
  }
}
</style>