<template>
  <div class="nav">
    <div class="logo">
      <router-link to="/"><img :src="`img/logo.png`" alt="" /></router-link>
    </div>
    <div class="items">
      <router-link to="/"> <span class="line"></span>HOME</router-link>
      <router-link to="/project">
        <span class="line"></span>PROJECTS</router-link
      >
      <router-link to="/about"> <span class="line"></span>ABOUT US</router-link>
      <router-link to="/contact"> <span class="line"></span>CONTACT US</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "top",
      cur: 1,
    };
  },
};
</script>

<style lang="stylus" scoped>
.nav {
  width: 1240px;
  height: 138px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 148px;
    height: 73px;

    a {
      display: block;
      width: 148px;
      height: 73px;

      img {
        width: 148px;
        height: 73px;
      }
    }
  }

  .items {
    width: 480px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      position: relative;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 30px;

      .line {
        display: none;
        position: absolute;
        height: 5px;
        background: #57a633;
        top: 14px;
        width: 100%;
        z-index: -1;
        padding: 0 3px;
        left: -3px;
      }

      &.router-link-exact-active {
        .line {
          display: block;
        }
      }
    }
  }
}
</style>
