import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../pages/about.vue')
  },
  {
    path: '/project',
    name: 'Project',
    component: () => import('../pages/project.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../pages/contact.vue')
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import('../pages/join.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
